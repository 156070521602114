$light: #fff;
$primary-color: rgb(101, 108, 254);
$secondary-color: #f9f9f9;
$gradient-color: #4025d9;
$extra-color: #181a23;
$black: #232323;
$black-light: #272727;
$border-color: #dedede;
$primary-font: "Barlow Condensed", sans-serif;
$secondary-font: "Montserrat", sans-serif;
